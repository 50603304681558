import React from 'react';
import './App.css';
import WorkflowBuilder from './WorkflowBuilder';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import FrontPage from './FrontPage/FrontPage';

function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const accessToken = urlParams.get("access_token");
  const domainUUID = urlParams.get("domain_uuid");

  // console.log(accessToken, domainUUID);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/all_flows" />} />
          <Route 
            path="/all_flows" 
            element={<FrontPage accessToken={accessToken} domainUUID={domainUUID} />} 
          />
          <Route 
            path="/flow_builder" 
            element={<WorkflowBuilder accessToken={accessToken} domainUUID={domainUUID} />} 
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
