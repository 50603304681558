import React from "react";
import { Handle, Position } from "reactflow";

const CreatedNewIVR = ({ data }) => {
  return (
    <div
      style={{
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "4px",
        background: "#fff",
        width: "250px",
        position: "relative",
      }}
    >
      <div>{data.label}</div>

      {/* Target handle on the left */}
      <Handle
        type="target"
        position={Position.Left}
        id="target"
        style={{ top: "50%",  background: "#333333",
            width: "12px", 
            height: "12px",  
            borderRadius: "50%", }}
      />

      {/* Generate 12 source handles on the right */}
      {[...Array(12)].map((_, index) => (
        <Handle
          key={`source${index}`}
          type="source"
          position={Position.Right}
          id={`source${index}`}
          style={{
            top: `${(index + 1) * (100 / 13)}%`, // Distribute handles vertically
            background: "#333333",
            width: "12px", 
            height: "12px",  
            borderRadius: "50%",
          }}
        />
      ))}
    </div>
  );
};

export default CreatedNewIVR;
